<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <section class="question-section section-gap">
                <div class="back-button">
                    <router-link :to="{name: 'company.assessments'}" class="back-button__icon">
                        <i class="eicon e-back"></i>
                    </router-link>
                    <div class="back-button__text text-capitalize">{{ $t("back to assessments") }}</div>
                </div>
                <div class="section-title mb-4 text-capitalize" v-if="!editMode">
                    {{ $t("create assessment") }}
                    <v-popover offset="10" style="display: initial;">
                        <button class="ej__tooltip__button mlF-1"><i class="eicon e-question"></i></button>
                        <template slot="popover">
                            <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                            <a v-close-popover href="https://easy.jobs/docs/how-to-add-an-assessment-in-easy-jobs/#1-toc-title" target="_blank">
                                {{ $t(`How to create Assessment?`) }}
                            </a>
                        </template>
                    </v-popover>
                </div>
                <div class="section-title mb-4 text-capitalize" v-if="editMode">{{ $t("edit assessment") }}
                    <v-popover offset="10" style="display: initial;">
                        <button class="ej__tooltip__button mlF-1"><i class="eicon e-question"></i></button>
                        <template slot="popover">
                            <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                            <a v-close-popover href="https://easy.jobs/docs/how-to-add-an-assessment-in-easy-jobs/#1-toc-title" target="_blank">
                                {{ $t(`How to edit Assessment?`) }}
                            </a>
                        </template>
                    </v-popover>
                </div>

                <div class="form-box">
                    <div class="form-note"><span class="text-capitalize">{{ $t("note") }}: </span>{{ $t("If you want to take a quiz please insert the Quiz time, marks per question and Quiz name here.") }} {{ $t("It will appear in the application process. (Example: 15 Minutes, 3 Marks per Question and Personality Test.)") }}</div>
                    <div class="row form-group">
                        <div class="col-md-6 mt-4">
                            <label class="text-capitalize">{{ $t("Select an existing question set") }}*</label>
                            <multiselect
                                    id="quiz_set"
                                    v-model="quiz_set"
                                    :options="quizSetOptions"
                                    :placeholder="$t('select a set.')"
                                    track-by="id"
                                    @input="selectQuestionSet"
                                    label="name"
                            ></multiselect>
                        </div>
                        <div class="col-md-6 mt-4">
                            <label class="text-capitalize" for="assessment_name">{{ $t("assessment name") }}*</label>
                            <input type="text" ref="assessment_name" v-model="form.assessment_name" id="assessment_name" maxlength="100" class="form-control" :placeholder="$t('assessment name')" required>
                            <error-message :message="$t(errors.assessment_name)"/>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-4 mt-4">
                            <label for="exam_duration" class="text-capitalize">{{ $t("assessment duration") }}*</label>
                            <input type="text" ref="exam_duration" v-model="form.exam_duration" id="exam_duration" class="form-control" :placeholder="$t('duration in minutes')" min="1" maxlength="5"/>
                            <error-message :message="$t(errors.exam_duration)"/>
                        </div>
                        <div class="col-md-4 mt-4">
                            <label for="marks_per_question">{{ $t("Marks Per Question") }}*</label>
                            <input type="text" ref="marks_per_question" v-model="form.marks_per_question" id="marks_per_question" class="form-control" :placeholder="$t('mark per question')" min="1" maxlength="5"/>
                            <error-message :message="$t(errors.marks_per_question)"/>
                        </div>
                    </div>
                </div>

                <assessment-question
                    v-for="(question, index) in form.questions"
                    :key="index"
                    :question="question"
                    :errors="errors"
                    :total-question="form.questions.length"
                    @moveUp="moveUp($event)"
                    @moveDown="moveDown($event)"
                    @deleteQuestion="deleteQuestion($event)"
                >
                </assessment-question>

                <div class="mt-30">
                    <button class="edit-button text-capitalize" @click.prevent="addQuestion">
                        <span class="edit-icon"><i class="eicon e-plus"></i></span>
                        <span class="text-capitalize">{{ $t("add question") }}</span>
                    </button>
                </div>

                <div class="section-gap d-flex">
                    <submit-button :click="saveAssessment" v-if="!editMode" class="info-button ml-auto text-capitalize" :disabled="saveButton.disabled" :loading="isLoading">{{ $t("Save") }}</submit-button>
                    <submit-button :click="updateAssessment" v-if="editMode" class="info-button ml-auto text-capitalize" :disabled="saveButton.disabled" :loading="isLoading">{{ $t("update") }}</submit-button>
                </div>
            </section>
        </div>
    </dashboard-layout>
</template>

<script>
import {getQuestionSet, getQuizMetaData} from "../../app/api/CommonRequest";
import client from '../../app/api/Client';
import Multiselect from 'vue-multiselect';
import AssessmentQuestion from "../../components/questions/AssessmentQuestion";
import evalution from "../../services/validations/evalution"

const DashboardLayout = () => import("../../layouts/DashboardLayout.vue");
const ErrorMessage = () => import("../../components/common/ErrorMessage.vue");

export default {
    components: {
        ErrorMessage,
        DashboardLayout,
        Multiselect,
        AssessmentQuestion
    },

    data() {
        return {
            quizSetOptions: [],
            quiz_set: '',
            form: {
                id: null,
                set_type: 3,
                assessment_name: '',
                exam_duration: '',
                marks_per_question: '',
                questions: []
            },
            isQuiz: true,
            setTypeOptions: [],
            questionTypeOptions: [],
            hasErrorInQuestions: false,
            errors: [],
            saveButton: {
                disabled: false,
            },
            isLoading: false,
        }
    },
    computed: {
        editMode() {
            return !!this.$route.params.id;
        }
    },
    methods: {
        moveUp(index) {
            [this.form.questions[index], this.form.questions[index - 1]] = [this.form.questions[index - 1], this.form.questions[index]];
            this.form.questions = this.form.questions.map(e => e);
        },

        moveDown(index) {
            [this.form.questions[index], this.form.questions[index + 1]] = [this.form.questions[index + 1], this.form.questions[index]];
            this.form.questions = this.form.questions.map(e => e);
        },

        getQuestionSets() {
            getQuizMetaData().then((data) => {
                this.quizSetOptions = data.quiz_set;
            });
        },

        selectQuestionSet() {
            if (!this.quiz_set) {
                this.$toast.error(this.$t('select a set.'));
            }
            getQuestionSet(this.quiz_set.id).then((data) => {
                this.form.questions = data.questions;
            });
        },

        validationErrorFocus() {
            let focusInputs = ['assessment_name', 'exam_duration', 'marks_per_question'];
            let focusKey = Object.keys(this.errors)[0];
            if(focusInputs.includes(focusKey)) {
                window.scrollTo(0,0);
                this.$refs[focusKey].focus();
            }
        },

        async saveAssessment() {

            this.validateAssessment();

            if (this.hasErrorInQuestions) {
                this.validationErrorFocus();
                return false;
            }

            if (this.form.questions.length === 0) {
                this.$toast.error(this.$t('Question not added.'));
                return false;
            } else {
                this.validateQuestions();

                if (this.hasErrorInQuestions) {
                    return false;
                }
            }

            this.isLoading = true;
            try {
                let {data} = await client().post(`/company/assessments/create`, this.form);
                if (data.status === 'SUCCESS') {
                    this.$toast.success(this.$t(data.message));
                        await this.$router.push({name: 'company.assessments'});
                } else {
                    this.$toast.error(this.$t(data.message));
                }
            } catch (err) {
                if (err.response.status === 422) {
                    this.errors = _helper.serializeValidationMessage(err.response.data.message);
                    this.$toast.error(this.$t('Oops! Please check your input'));
                } else {
                    this.$toast.error(this.$t(err.response.data.message));
                }
            }
            this.isLoading = false;
        },

        async updateAssessment() {

            this.validateAssessment();

            if (this.hasErrorInQuestions) {
                this.validationErrorFocus();
                return false;
            }

            if (!this.form.id) {
                this.$toast.error(this.$t('Invalid id'));
                return false;
            }

            if (this.form.questions.length === 0) {
                this.$toast.error(this.$t('Question not added.'));
                return false;
            } else {
                this.validateQuestions();

                if (this.hasErrorInQuestions) {
                    return false;
                }
            }

            this.isLoading = true;
            try {
                let {data} = await client().post(`/company/assessments/${this.form.id}/update`, this.form);
                if (data.status === 'SUCCESS') {
                    this.$toast.success(this.$t(data.message));
                    await this.$router.push({name: 'company.assessments'});
                } else {
                    this.$toast.error(this.$t(data.message));
                }
            } catch (err) {
                if (err.response.status === 422) {
                    this.errors = _helper.serializeValidationMessage(err.response.data.message);
                    this.$toast.error(this.$t('Oops! Please check your input'));
                } else {
                    this.$toast.error(this.$t(err.response.data.message));
                }
            }
            this.isLoading = false;
        },

        validateAssessment() {
            let { errors, hasErrorInQuestions } = evalution.validateAssessment(this.form);
            this.errors = errors;
            this.hasErrorInQuestions = hasErrorInQuestions;
        },

        validateQuestions() {
            let {hasErrorInQuestions} = evalution.validateQuestions(this.form.questions, true);
            this.hasErrorInQuestions = hasErrorInQuestions;
        },

        addQuestion() {
            this.validateAssessment();

            if (this.hasErrorInQuestions) {
                this.validationErrorFocus();
                return false;
            }

            this.validateQuestions();

            if (this.hasErrorInQuestions) {
                return false;
            }

            this.form.questions.push({
                id: null,
                type: '',
                title: "",
                options: [
                    {
                        id: null,
                        title: '',
                    },
                    {
                        id: null,
                        title: '',
                    }
                ],
                answers: [],
                isValid: false,
                errors: [],
                isMultiple: false
            });
        },

        deleteQuestion(key) {
            let message = {
                title: this.$t("Confirmation"),
                body: this.$t("are you sure you wanted to delete this question?")
            };

            this.$dialog.confirm(message).then(() => {
                this.form.questions.splice(key, 1);
            });
        },

        async loadEditData() {
            if (this.editMode) {
                this.form.id = this.$route.params.id;
                await this.loadQuestionSetData();
            }
        },

        async loadQuestionSetData() {
            let {data: {data}} = await client().get(`/company/assessments/${this.$route.params.id}`);
            this.form = data;
            this.isQuiz = true;
        },
    },
    async created() {
        await this.getQuestionSets();
        if (this.$route.params.id) {
            await this.loadEditData();
        }
    }
}
</script>
