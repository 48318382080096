<template>
    <div class="screening-question-card" >
        <div class="d-flex justify-content-between align-items-center">
            <h3 class="screening-question-card__title">
                {{ $t("Question") }}: {{ (index + 1) }}
            </h3>

            <div class="d-flex ml-auto justify-content-end my-2">
                <a href="javascript:void(0)" class="control-button control-button--info has_bg_color" @click.prevent="addOption(question)">
                    <div class="control-button__icon">
                        <i class="eicon e-plus"></i>
                    </div>
                    <span>Add Option</span>
                </a>
                <a href="javascript:void(0)" class="control-button control-button--primary has_bg_color" @click="$emit('moveUp', index)" v-if="index > 0">
                    <div class="control-button__icon">
                        <i class="eicon e-arrow-up"></i>
                    </div>
                    <span>Move Up</span>
                </a>

                <a href="javascript:void(0)" class="control-button control-button--primary has_bg_color" @click="$emit('moveDown', index)" v-if="isLastQuestion">
                    <div class="control-button__icon">
                        <i class="eicon e-arrow-down"></i>
                    </div>
                    <span>Move Down</span>
                </a>
                <a href="javascript:void(0)" @click="$emit('deleteQuestion', index)" class="erase-button"><i class="eicon e-delete"></i><span>Delete</span></a>
            </div>
        </div>
        <div class="form-box">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="text-capitalize" :for="`question-${index}`">{{ $t("type your question") }}*</label>
                        <input type="text" maxlength="250" v-model.trim="question.title" :id="`question-${index}`" class="form-control" :placeholder="$t('type question')"/>
                        <error-message :message="$t(errors[`questions.${index}.title`])"/>
                        <error-message :message="$t(question.errors['title'])"/>
                    </div>
                </div>
            </div>
            <div class="row" v-if="question.options">
                <div class="col-md-3" v-for="(option, key) in question.options">
                    <div class="form-group screening-question-answer">
                        <label :for="`option-${index}-${key}`"> {{ $t("Option") }} {{ (key + 1) }}</label>
                        <div class="d-flex align-items-center">
                            <label class="radio">
                                <input type="radio" :value="key" v-model.trim="question.answers"/>
                                <span></span>
                            </label>
                            <input type="text" :id="`option-${index}-${key}`" v-model.trim="option.title" class="form-control" :placeholder="$t('Option') + ` ${(key + 1)}`"/>
                            <a href="javascript:void(0)" class="remove-button" @click.prevent="removeOption(question, key)">
                                <i class="eicon e-close"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-note mt-2">
                <span class="text-capitalize">{{ $t("Notes") }}: </span>
                {{ $t('The radio button is for correct answer, you have to choose one correct answer.') }}
            </div>
            <div class="error-message mt-2" v-if="question.errors.length">
                {{ $t(question.errors.join(' ')) }}
            </div>
        </div>
    </div>
</template>

<script>

const ErrorMessage = () => import("../../components/common/ErrorMessage.vue");

export default {
    name: "AssessmentQuestion",
    props: [
        'question',
        'errors',
        'totalQuestion'
    ],

    components: {
        ErrorMessage
    },

    computed: {
        isLastQuestion() {
            return this.totalQuestion - 1 > this.index;
        },
        index() {
            return this.$vnode.key;
        }
    },

    methods: {
        addOption(question) {
            question.options.push({
                id: null,
                title: '',
            });
        },

        removeOption(question, index) {
            if(question.answers === index) {
                question.answers = '';
            }
            question.options.splice(index, 1);
        },
    }
}
</script>

<style scoped>

</style>
